/* Tables */

.table {
  margin-bottom: 0;

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-2;
      font-weight: 500;

      i {
        margin-left: 0.325rem;
      }
    }
  }

  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    height: 35px;
    padding: $table-cell-padding;

    img {
      &:not(.thumb-image) {
        @extend .img-ss;
        border-radius: 100%;
      }

      &.thumb-image {
        width: 50px;
        height: 50px;
        border: 1px solid #f7f8ff;
      }
    }

    .badge {
      margin-bottom: 0;
    }

    .form-check,
    .form-radio {
      margin-top: 0;
      margin-bottom: -0px;
    }
  }

  td {
    .form-check {
      .form-check-label {
        input:checked+.input-helper {
          &:after {
            line-height: 1px;
          }
        }
      }
    }
  }

  &.table-borderless {
    border: none;

    td,
    th,
    tr {
      border: none;
    }
  }

  &.table-bordered {
    thead {
      border: 1px solid $border-color;
      border-bottom: none;

      tr {
        &:not(.enable-border) {
          th {
            border-left: none;
            border-right: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {}
      }
    }

    &.table-edge-border-0 {
      border-left: none;
      border-right: none;
      border-bottom: none;

      thead {
        border-left: none;
        border-right: none;
      }

      th,
      td {
        &:first-child {
          border-left: 1px solid transparent;
        }

        &:last-child {
          border-right: 1px solid transparent;
        }
      }
    }
  }

  &.table-striped {
    thead tr {
      background-color: $table-accent-bg;
    }
  }
}